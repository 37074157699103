export const resources = {
  common: 'common',
  home: 'home',
  overview: 'overview'
};

export const translations = {
  common: {
    failedToLoadData: 'failedToLoadData',
    search: 'search',
    clear: 'clear',
    cancel: 'cancel',
    next: 'next',
    previous: 'previous',
    send: 'send',
    ticketTypes: {
      errorReports: 'ticketTypes.errorReports',
      supportReports: 'ticketTypes.supportReports'
    },
    status: {
      ticket: {
        done: 'status.ticket.done',
        inProgress: 'status.ticket.inProgress',
        investigation: 'status.ticket.investigation',
        other: 'status.ticket.other',
        pending: 'status.ticket.pending',
        registered: 'status.ticket.registered',
        removed: 'status.ticket.removed',
        test: 'status.ticket.test',
        delivered: 'status.ticket.delivered'
      },
      signatory: {
        waiting: 'status.signatory.waiting',
        started: 'status.signatory.started',
        inProgress: 'status.signatory.inProgress',
        completed: 'status.signatory.completed',
        rejected: 'status.signatory.rejected',
        canceled: 'status.signatory.canceled',
        expired: 'status.signatory.expired',
        forwarded: 'status.signatory.forwarded',
        notSet: 'status.signatory.notSet',
        warning: 'status.signatory.warning'
      }
    },
    validation: {
      required: 'validation.required'
    }
  },
  home: {
    title: 'title',
    text: 'text',
    tiles: {
      support: {
        title: 'tiles.support.title',
        text: 'tiles.support.text'
      },
      error: {
        title: 'tiles.error.title',
        text: 'tiles.error.text'
      },
      overview: {
        title: 'tiles.overview.title',
        text: 'tiles.overview.text'
      }
    },
    activities: {
      title: 'activities.title',
      text: 'activities.text',
      failedToGetActivities: 'activities.failedToGetActivities',
      failedToGetStatistics: 'activities.failedToGetStatistics',
      document: 'activities.document',
      documents: 'activities.documents',
      updatedOn: 'activities.updatedOn',
      hasSigned: 'activities.hasSigned'
    }
  },
  overview: {
    title: 'List of Ticket registered',
    text: '',
    table: {
      title: 'table.title',
      submittedBy: 'table.submittedBy',
      type: 'table.type',
      updated: 'table.updated',
      created: 'table.created',
      status: 'table.status',
      errorReports: 'table.errorReports',
      supportReports: 'table.supportReports'
    },
    filter: {
      typeOfTicket: 'filter.typeOfTicket',
      statuses: 'filter.statuses',
      errorReports: 'filter.errorReports',
      supportReports: 'filter.supportReports',
      queryString: 'filter.queryString',
      dateFrom: 'filter.dateFrom',
      dateTo: 'filter.dateTo',
      investigation: 'filter.investigation',
      test: 'filter.test',
      inProcess: 'filter.inProcess',
      registered: 'filter.registered',
      removed: 'filter.removed',
      pending: 'filter.pending',
      done: 'filter.done',
      other: 'filter.other',
      delivered: 'filter.delivered'
    }
  }
};
