import DateTimeParser from '../../../../utils/DateTimeParser';

export const formInitialValues = {
  dateFrom: DateTimeParser.subtractYears(1),
  dateTo: DateTimeParser.toLocaleDateString(new Date()),
  queryString: '',
  statuses: {
    inProcess: false,
    pending: false,
    registered: false,
    done: false,
    investigation: false,
    test: false,
    removed: false,
    other: false,
    delivered: false
  },
  types: {
    errorReports: false,
    supportReports: false
  }
};


export const ticketStatus = {
  registered: 0,
  inProgress: 1,
  done: 2,
  other: 3,
  investigation: 4,
  test: 5,
  removed: 6,
  pending: 7,
  delivered: 8
};

export const ticketTypes = {
  errorReports: 1,
  supportReports: 2
};
