import { PieChart, Pie, Cell, Legend, Label } from 'recharts';
import { Card, useToastAction } from '@metaforcelabs/metaforce-core';
import { useState, useCallback, useEffect } from 'react';
import { getStatistics } from '../../../../api/dashboards';
import { resources, translations } from '../../../../translations/translationsConstants';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function TicketStatistics({ category }) {
  const [statisticsData, setStatisticsData] = useState([]);
  const [statisticsDateFilter, setStatisticsDateFilter] = useState(30);
  const getStatisticsAction = useToastAction();
  const history = useHistory();
  const { t } = useTranslation([resources.home]);
  const mockRadian = Math.PI / 180;

  let renderLabel = function (entry) {
    const radius = entry.innerRadius + (entry.outerRadius - entry.innerRadius) * 0.6;
    const x = entry.cx + 0 + radius * Math.cos(-entry.midAngle * mockRadian);
    const y = entry.cy + 0 + radius * Math.sin(-entry.midAngle * mockRadian);
  
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > entry.cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {entry.value}
      </text>
    );
  };

  const filterValuesSelection = [
    {
      value: 7,
      label: 'Last 7 days'
    },
    {
      value: 30,
      label: 'Last 30 days'
    },
    {
      value: 90,
      label: 'Last 90 days'
    },
    {
      value: 365,
      label: 'Last 12 months'
    }
  ];

  const chartColorBase = [
    'Gray',
    'Teal',
    'FireBrick',
    'SaddleBrown',
    'DarkSlateBlue',
    'DarkMagenta',
    'Tomato',
    'GoldenRod'
    
  ];

  const fetchStatistics = ( dateLimit ) => {
    getStatisticsAction.execute(async () => {
      const fetchResult = await getStatistics({
        daysLimit: dateLimit,
        type: category === 'Support' ? 2 : 1,
      });
      let statisticsArray = [];

      for (let i = 0; i < fetchResult.length; i++) {
        if(fetchResult[i].value !== 0) {
          statisticsArray.push({
            ...fetchResult[i],
            index: i
          })
        }
      }
      setStatisticsData(statisticsArray);
    }, t(translations.home.activities.failedToGetStatistics));
  };

  const getOverallCount = () => {
    let overallCount = 0;
    statisticsData.forEach(entry => (overallCount = overallCount + entry.value));
    return overallCount;
  };

  const getLegendIcon = color => {
    return (
      <>
        <div
          className="mt-1"
          style={{ backgroundColor: color, height: 10 + 'px', width: 10 + 'px' }}
        ></div>
      </>
    );
  };

  const renderLegend = props => {
    const { payload } = props;
    return (
      <>
        <ul className="grid grid-cols-3">
          {payload.map((entry, index) => (
            <li key={`item-${index}`} className="grid grid-cols-6">
              {getLegendIcon(entry.color)}
              <div className="col-span-5 text-sm">{entry.value}</div>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const updateChartWithNewDateLimit = ( dateLimit ) => {
    setStatisticsDateFilter(dateLimit);
    fetchStatistics(dateLimit);
  };

  useEffect(() => {
    fetchStatistics(statisticsDateFilter);
  }, []);

  return (
    <>
      <Card className="place-content-center mt-5 2xl:mt-0">
      <div className="grid grid-cols-4">
        <div className="font-medium mt-1">{category}</div>

        <div className="col-start-3 col-span-2">
          <select
            name="role"
            id="roleMoveTo"
            onChange={e => updateChartWithNewDateLimit(e.target.value)}
            value={statisticsDateFilter}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          >
            {filterValuesSelection.map(r => {
              return <option value={r.value}>{r.label}</option>;
            })}
          </select>
        </div>
      </div>
      { statisticsData.length > 0 ?
        (
          <PieChart width={320} height={320}>
            <Pie
              data={statisticsData}
              cx="50%"
              cy="50%"
              innerRadius={50}
              outerRadius={100}
              labelLine={false}
              label={renderLabel}
              fill="#8884d8"
              dataKey="value"
            >
              {statisticsData.map((entry, index) => (
                <Cell 
                  key={`cell-${index}`} 
                  fill={chartColorBase[index % chartColorBase.length]} 
                  onClick={() => history.push('/overview', { statusFilter: entry.index, dateFromFilter: statisticsDateFilter, type: (category === 'Support' ? 2 : 1) })}
                />
              ))}
              <Label
                value={getOverallCount()}
                position="center"
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold'
                }}
              />
            </Pie>
            <Legend content={renderLegend} />
          </PieChart>
        )
      :
        (
          <div className='w-100 h-100 text-center p-10'>During selected period of time<br/>there wasn't any tickets<br/>registered or updated</div>
        )
      }
      </Card>
    </>
  );
}
