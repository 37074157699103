import { SubmitButton } from "../SubmitButton"

export const FormikSubmitButton = ({
  text,
  className,
  formikProps,
}) => {
  return (
    <SubmitButton
      text={text}
      className={className}
      disabled={Object.keys(formikProps.errors).length > 0 || formikProps.isSubmitting}
    />
  )
}