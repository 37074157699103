import React from 'react';
import { useClassNames } from '../../../hooks/useClassNames';

export default function Tile({ title, text, icon, iconBgColor, iconTextColor }) {
  const classNames = useClassNames();

  const IconComponent = icon;

  return (
    <div className="p-5 relative flex justify-start items-center md:items-start md:flex-col h-full">
      <div className="flex items-center ">
        <IconComponent
          className={classNames.classNames(
            'p-3 inline-flex items-center justify-center h-12 w-12 rounded-lg',
            iconBgColor || 'bg-brandPrimary',
            iconTextColor || 'text-white'
          )}
        />

        <h1 className="text-lg ml-5 font-medium text-gray-900">{title}</h1>
      </div>

      <div className="mt-5 lg:h-3/4 text-sm font-medium text-gray-900">
        <p className="text-xs font-normal text-gray-500 leading-5 hidden lg:block">{text}</p>
      </div>
    </div>
  );
}
