export const config = {
  tokenStorageKey: '@MetaForce:userToken',
  apiBaseUrl: window._env_.REACT_APP_API_BASE_URL,
  identityBaseUrl: window._env_.REACT_APP_IDENTITY_BASE_URL,
  centerpointBaseUrl: window._env_.REACT_APP_CENTERPOINT_BASE_URL,
  centerpointUiBaseUrl: window._env_.REACT_APP_CENTERPOINT_UI_BASE_URL,
  productLicense: '62d55b845ad30d2f5777ddf0',
  appBaseUrl: window._env_.REACT_APP_REDIRECT_URI,
  docsUrl: window._env_.REACT_APP_DOCUMENTATION_URL,
};

export const oidcConfig = {
  client_id: 'support_web',
  redirect_uri: window._env_.REACT_APP_REDIRECT_URI,
  response_type: 'code',
  post_logout_redirect_uri: window._env_.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  scope: 'openid profile email api.internal offline_access',
  authority: window._env_.REACT_APP_AUTHORITY,
  silent_redirect_uri: window._env_.REACT_APP_SILENT_REDIRECT_URI,
  automaticSilentRenew: true,
  loadUserInfo: true
};