import DateTimeParser from '../../../../utils/DateTimeParser';
import { Formik, Form, FieldArray } from 'formik';
import { FormikCheckbox } from '../../../../components/Form/Formik/FormikCheckbox';
import { FormikSubmitButton } from '../../../../components/Form/Formik/FormikSubmit';
import { formInitialValues, ticketStatus, ticketTypes } from '../consts';
import FormikInput from '../../../../components/Form/Formik/FormikInput';
import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../../translations/translationsConstants';
import DateField from '../../../../components/DateField';
import Button from '../../../../components/Button';

export default function Filter({ onSubmit, inputTicketType, inputTicketStatus }) {
  const { t } = useTranslation([resources.overview, resources.common]);
  let formInitialValuesWithInput = JSON.parse(JSON.stringify(formInitialValues));

  const keys = Object.keys(ticketStatus).sort(function(a, b){
    return ticketStatus[a] - ticketStatus[b];
  });

  if(inputTicketType === 1) formInitialValuesWithInput.types.errorReports = true;
  else if(inputTicketType === 2)  formInitialValuesWithInput.types.supportReports = true;

  if(inputTicketStatus !== undefined) formInitialValuesWithInput.statuses[keys[inputTicketStatus]] = true; //Ticket status can be equal to 0

  const prepareTypes = types => {
    return Object.keys(types)
      .filter(type => types[type])
      .map(type => ticketTypes[type]);
  };

  const prepareStatuses = statuses => {
    return Object.keys(statuses)
      .filter(state => statuses[state])
      .map(state => ticketStatus[state]);
  };

  return (
    <>
      <Formik
        initialValues={formInitialValuesWithInput}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          onSubmit(
            {
              queryString: values.queryString,
              fromDate: values.dateFrom
                ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setSOD(values.dateFrom))
                : null,
              toDate: values.dateTo
                ? DateTimeParser.toLocalJSONFormat(DateTimeParser.setEOD(values.dateTo))
                : null,
              types: prepareTypes(values.types),
              statuses: prepareStatuses(values.statuses)
            },
            actions.setSubmitting
          );
        }}
      >
        {props => (
          <Form>
            <div className="w-full mt-5 mb-10 pb-5 border-b border-gray-200">
              <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-3">
                <div className="col-span-1">
                  <FormikInput
                    label={t(translations.overview.filter.queryString)}
                    name="queryString"
                    formikProps={props}
                  />

                  <div className="mt-3 grid grid-cols-1 gap-6 sm:grid-cols-2">
                    <DateField
                      navbar={true}
                      label={t(translations.overview.filter.dateFrom)}
                      name="dateFrom"
                      value={props.values.dateFrom}
                      maxDate={props.values.dateTo}
                      onChange={(v, e) => {
                        props.setFieldValue('dateFrom', v);
                      }}
                    />

                    <DateField
                      navbar={true}
                      label={t(translations.overview.filter.dateTo)}
                      name="dateTo"
                      value={props.values.dateTo}
                      minDate={props.values.dateFrom}
                      onChange={(v, e) => {
                        props.setFieldValue('dateTo', v);
                      }}
                    />
                  </div>
                </div>

                <div className="col-span-1 sm:col-span-2">
                  <div className="grid grid-cols-1 gap-3 sm:grid-cols-3 mt-3">
                    <div>
                      <p>{t(translations.overview.filter.typeOfTicket)}</p>

                      <div>
                        <FieldArray
                          name="friends"
                          render={() => (
                            <>
                              <FormikCheckbox
                                label={`${t(translations.overview.filter.errorReports)}`}
                                name={`types.errorReports`}
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />

                              <FormikCheckbox
                                label={`${t(translations.overview.filter.supportReports)}`}
                                name={`types.supportReports`}
                                formikProps={props}
                                styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                              />
                            </>
                          )}
                        />
                      </div>
                    </div>

                    <div className='col-span-2'>
                      <p>{t(translations.overview.filter.statuses)}</p>

                      <div className='grid grid-cols-3'>
                        <div>

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.registered)}`}
                            name="statuses.registered"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.inProcess)}`}
                            name="statuses.inProgress"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.investigation)}`}
                            name="statuses.investigation"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.pending)}`}
                            name="statuses.pending"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />
                        </div>
                        <div>
                          <FormikCheckbox
                            label={`${t(translations.overview.filter.test)}`}
                            name="statuses.test"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.done)}`}
                            name="statuses.done"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.removed)}`}
                            name="statuses.removed"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />

                          <FormikCheckbox
                            label={`${t(translations.overview.filter.delivered)}`}
                            name="statuses.delivered"
                            formikProps={props}
                            styles="focus:ring-gray-400 focus:border-gray-400 h-4 w-4 text-indigo-600 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-6">
                <Button variant={Button.variants.secondary} onClick={() => { props.resetForm(); props.handleSubmit();}}>
                  {t(translations.common.clear, { ns: resources.common })}
                </Button>

                <FormikSubmitButton
                  id="search"
                  text={t(translations.common.search, { ns: resources.common })}
                  className="ml-2 i"
                  formikProps={props}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
