import { get, post } from './api';

export const getDialogInstanceDetailsApi = async type => {
  return await get(`Support/GetDialogInstanceDetails/${type}`);
};

export const getUserAccessApi = async () => {
  return await get(`Support/GetUserAccess`);
};

export const getStatistics = async data => {
  return await post(`Support/GetStatistics`, data);
};