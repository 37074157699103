import { useTranslation } from 'react-i18next';
import { resources, translations } from '../../../translations/translationsConstants';
import {
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableHeader,
  TableRow
} from '../../../components/Table';
import { getTicketsApi } from '../../../api/overview';
import { useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useCallback, useEffect, useState } from 'react';
import DateTimeParser from '../../../utils/DateTimeParser';
import Filter from './components/Filter';
import { Badge } from '../../../components/Badge';
import { ticketStatus, ticketTypes } from './consts';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Overview = () => {
  const { t } = useTranslation([resources.overview, resources.home, resources.common]);
  const history = useHistory();
  const [ticketsList, setTicketsList] = useState([]);
  const [filters, setFilters] = useState({
    fromDate: (history.location.state?.dateFromFilter !== undefined) ? DateTimeParser.addDays((-1) * history.location.state?.dateFromFilter) : DateTimeParser.subtractYears(1),
    dateTo: DateTimeParser.toLocaleDateString(new Date()),
    statuses: (history.location.state?.statusFilter !== undefined) ? [history.location.state.statusFilter] : [],
    types: (history.location.state?.type !== undefined) ? [history.location.state.type] : [],
    queryString: ''
  });
  
  const getTicketsAction = useToastAction();

  const fetchTickets = useCallback((data, setSubmitting) => {
    getTicketsAction.execute(async () => {
      const getTicketsApiResult = await getTicketsApi(data);

      setTicketsList(getTicketsApiResult);

      if (setSubmitting) {
        setSubmitting(false);
      }
    }, t(translations.home.activities.failedToGetActivities));
  }, []);

  const filterList = order => {
    if (filters.queryString) {
      const foundInCreatedBy = order.ticketSubject.includes(filters.queryString);

      if (!foundInCreatedBy) {
        return false;
      }
    }

    if (filters.statuses.length > 0) {
      const foundStatus = filters.statuses.some(s => s === order.status);

      if (!foundStatus) {
        return false;
      }
    }

    if (filters.types.length > 0) {
      const foundType = filters.types.some(s => s === order.ticketType);

      if (!foundType) {
        return false;
      }
    }

    return true;
  };

  const handleOnFilter = (values, setSubmitting) => {
    setFilters(values);
    fetchTickets(values, setSubmitting);
  };

  const getStatus = status => {
    switch (status) {
      case ticketStatus.investigation:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.investigation, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.inProgress:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.inProgress, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.test:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.test, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.removed:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.removed, {
              ns: resources.common
            })}
          />
        );
        case ticketStatus.pending:
          return (
            <Badge
              type="info"
              text={t(translations.common.status.ticket.pending, {
                ns: resources.common
              })}
            />
          );
      case ticketStatus.done:
        return (
          <Badge
            type="success"
            text={t(translations.common.status.ticket.done, {
              ns: resources.common
            })}
          />
        );
        case ticketStatus.delivered:
          return (
            <Badge
              type="success"
              text={t(translations.common.status.ticket.delivered, {
                ns: resources.common
              })}
            />
          );
      case ticketStatus.registered:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.registered, {
              ns: resources.common
            })}
          />
        );
      default:
        return (
          <Badge
            type="default"
            text={t(translations.common.status.ticket.other, {
              ns: resources.common
            })}
          />
        );
    }
  };

  const getType = type => {
    return type === ticketTypes.errorReports
      ? t(translations.overview.table.errorReports)
      : t(translations.overview.table.supportReports);
  };

  useEffect(() => {
    fetchTickets(filters);
  }, []);

  return (
    <>
      <div>
        <div className="flex flex-col pb-5 mb-10 border-b border-gray-200">
          <h1 className="text-3xl font-bold">{t(translations.overview.title)}</h1>
          <p className="mt-2 max-w-4xl text-sm text-gray-500">{t(translations.overview.text)}</p>
        </div>

        <Filter orderList={ticketsList} defaultFilter={filters} inputTicketStatus={history.location.state?.statusFilter} inputTicketType={history.location.state?.type} onSubmit={handleOnFilter} />

        <div className="relative">
          <Table>
            <TableHead>
              <TableHeader>{t(translations.overview.table.title)}</TableHeader>
              <TableHeader additionalClass={'hidden sm:table-cell'}>{t(translations.overview.table.submittedBy)}</TableHeader>
              <TableHeader>{t(translations.overview.table.type)}</TableHeader>
              <TableHeader additionalClass={'hidden lg:table-cell'}>{t(translations.overview.table.updated)}</TableHeader>
              <TableHeader additionalClass={'hidden lg:table-cell'}>{t(translations.overview.table.created)}</TableHeader>
              <TableHeader>{t(translations.overview.table.status)}</TableHeader>
            </TableHead>
            <TableBody>
              {ticketsList.filter(filterList).map((row, index) => (
                <React.Fragment key={row.id}>
                  <TableRow className={index % 2 === 0 ? undefined : 'bg-gray-50'}>
                    <TableColumn additionalClass={'overflow-ellipsis overflow-hidden max-w-120 lg:max-w-150 xl:max-w-250'}>
                      <a
                        href={row.dialogUrl}
                        className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                        target="_blank"
                      >
                        {row.ticketSubject}
                      </a>
                    </TableColumn>
                    <TableColumn additionalClass={'hidden sm:table-cell'}>{row.submittedBy}</TableColumn>
                    <TableColumn>{getType(row.ticketType)}</TableColumn>
                    <TableColumn additionalClass={'hidden lg:table-cell'}>{stringToLocaleDateTimeString(row.updatedDate)}</TableColumn>
                    <TableColumn additionalClass={'hidden lg:table-cell'}>{stringToLocaleDateTimeString(row.createdDate)}</TableColumn>
                    <TableColumn>{getStatus(row.status)}</TableColumn>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Overview;
