import React from 'react';

export const FormContext = React.createContext();
export const ShowHideContext = React.createContext();
export const ElementContext = React.createContext();
export const ElementPropertiesContext = React.createContext();
export const RowContext = React.createContext();
export const ColumnContext = React.createContext();
export const ContainerContext = React.createContext();

export const DesignActionbarContext = React.createContext();
export const OidcRoutesContext = React.createContext();
