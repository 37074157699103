import { EyeIcon, PencilAltIcon } from '@heroicons/react/outline';
import { Card, useToastAction } from '@metaforcelabs/metaforce-core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tile } from '../../../components/Layout';
import { resources, translations } from '../../../translations/translationsConstants';
import './styles.scss';
import { getDialogInstanceDetailsApi, getUserAccessApi } from '../../../api/dashboards';
import { stringToLocaleDateTimeString } from '../../../utils/date';
import DateTimeParser from '../../../utils/DateTimeParser';
import { getTicketsApi } from '../../../api/overview';
import { ticketStatus, ticketTypes } from '../Overview/consts';
import { Badge } from '../../../components/Badge';
import { supportRegistrationTypes } from '../../../utils/constants';
import TicketStatistics from './components/TicketStatistics';

export default function Dashboard() {
  const [activities, setActivities] = useState([]);
  const [userAccess, setUserAccess] = useState({
    supportAccess: false
  });

  const activitiesAction = useToastAction();
  const userAccessAction = useToastAction();
  const dialogInstanceAction = useToastAction();
  const { t } = useTranslation([resources.home, resources.common]);

  const getUserAccess = () => {
    userAccessAction.execute(async () => {
      const result = await getUserAccessApi();
      setUserAccess(result);
    }, t(translations.common.failedToLoadData));
  };

  const fetchActivities = useCallback(() => {
    activitiesAction.execute(async () => {
      const activitiesResult = await getTicketsApi({
        dateFrom: DateTimeParser.subtractYears(1),
        dateTo: DateTimeParser.toLocaleDateString(new Date()),
        statuses: [],
        types: [],
        queryString: '',
        limit: 5
      });
      setActivities(activitiesResult);
    }, t(translations.home.activities.failedToGetActivities));
  }, []);

  const handleOnLinkClick = type => {
    dialogInstanceAction.execute(async () => {
      const dialogInstanceResult = await getDialogInstanceDetailsApi(type);

      window.open(dialogInstanceResult.dialogUrl, '_blank');
    }, '');
  };

  const getStatus = status => {
    switch (status) {
      case ticketStatus.investigation:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.investigation, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.inProgress:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.inProgress, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.test:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.test, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.removed:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.removed, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.pending:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.pending, {
              ns: resources.common
            })}
          />
        );
      case ticketStatus.done:
        return (
          <Badge
            type="success"
            text={t(translations.common.status.ticket.done, {
              ns: resources.common
            })}
          />
        );
        case ticketStatus.delivered:
          return (
            <Badge
              type="success"
              text={t(translations.common.status.ticket.delivered, {
                ns: resources.common
              })}
            />
          );
      case ticketStatus.registered:
        return (
          <Badge
            type="info"
            text={t(translations.common.status.ticket.registered, {
              ns: resources.common
            })}
          />
        );
      default:
        return (
          <Badge
            type="default"
            text={t(translations.common.status.ticket.other, {
              ns: resources.common
            })}
          />
        );
    }
  };

  const getType = type => {
    return type === ticketTypes.errorReports
      ? t(translations.common.ticketTypes.errorReports, {
          ns: resources.common
        })
      : t(translations.common.ticketTypes.supportReports, {
          ns: resources.common
        });
  };

  useEffect(() => {
    getUserAccess();
    fetchActivities();
  }, []);

  return (
    <>
      <div className="w-full h-full">
        <h1 className="text-3xl font-bold">{t(translations.home.title)}</h1>
        <h3 className="mt-4 text-gray-600">{t(translations.home.text)}</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 lg:gap-10 mt-10">
          {userAccess.supportAccess && (
            <div
              className="cursor-pointer"
              onClick={() => handleOnLinkClick(supportRegistrationTypes.support)}
            >
              <Card shouldRemovePadding>
                <Tile
                  title={t(translations.home.tiles.support.title)}
                  text={t(translations.home.tiles.support.text)}
                  icon={PencilAltIcon}
                  iconBgColor="bg-pink-500"
                />
              </Card>
            </div>
          )}
          <div
            className="cursor-pointer"
            onClick={() => handleOnLinkClick(supportRegistrationTypes.error)}
          >
            <Card shouldRemovePadding>
              <Tile
                title={t(translations.home.tiles.error.title)}
                text={t(translations.home.tiles.error.text)}
                icon={EyeIcon}
                iconBgColor="bg-purple-500"
              />
            </Card>
          </div>

          <Card shouldRemovePadding>
            <Link to="/overview">
              <Tile
                title={t(translations.home.tiles.overview.title)}
                text={t(translations.home.tiles.overview.text)}
                icon={EyeIcon}
                iconBgColor="bg-yellow-500"
              />
            </Link>
          </Card>
        </div>

        <div className="mt-10 overflow-hidden sm:rounded-md grid grid-cols-1">
          <div className="mb-6">
            <h2 className="col-start-1 text-lg font-medium text-gray-900">
              {t(translations.home.activities.title)}
            </h2>

            <p className="col-start-1 mt-1 text-sm text-gray-500">
              {t(translations.home.activities.text)}
            </p>
          </div>
          <div className="2xl:grid grid-cols-2 gap-8">
              {activities.length > 0 && (
                <div className="shadow-sm rounded-lg border border-gray-200 bg-white min-h-2 ">
                  <ul className="divide-y divide-gray-200">
                    {activities.map((a, index) => (
                      <li key={index}>
                        <div className="block">
                          <div className="flex items-center p-4">
                            <div className="min-w-0 flex-1 flex items-center">
                              <div className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                  <a
                                    href={a.dialogUrl}
                                    className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                                    target="_blank"
                                  >
                                    {a.ticketSubject}
                                  </a>
                                  <p className="mt-2 flex items-center text-sm text-gray-500">
                                    {getType(a.ticketType)}
                                  </p>
                                </div>
                                <div className="mt-4 md:mt-0">
                                  <div>
                                    <p className="text-sm text-gray-500">
                                      {t(translations.home.activities.updatedOn, {
                                        date: stringToLocaleDateTimeString(a.updatedDate)
                                      })}
                                    </p>
                                    <p className="mt-2 flex items-center text-sm text-gray-500 ">
                                      {getStatus(a.status)}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}

            <div>
              <div className="grid md:grid-cols-2 gap-4">
                {userAccess.supportAccess && (
                  <TicketStatistics category={'Support'} />
                )}
                <TicketStatistics category={'Errors'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
