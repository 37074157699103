import {
  AuthenticationProvider,
  WebStorageStateStore,
  oidcLog,
  useReactOidc
} from '@axa-fr/react-oidc-context';
import { OidcSecure } from '@axa-fr/react-oidc-context';
import { getUserManager } from '@axa-fr/react-oidc-core';
import i18next from 'i18next';
import { WhiteLabelProvider } from '@metaforcelabs/metaforce-core';
import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { setAuthHeader } from './api/api';
import { getCustomerInfo } from './api/customer';
import { getUserProfile } from './api/userProfile';
import { OidcRoutesContext } from './contexts';
import { useLicense } from './hooks/useLicense';
import { useToastAction } from './hooks/useToastAction';
import AdminLayout from './layouts/AdminLayout';
import Dashboard from './screens/Admin/Dashboard';
import MissingLicense from './screens/Errors/MissingLicense';
import { NotFound } from './screens/Errors/NotFound';
import { Unauthorized } from './screens/Errors/Unauthorized';
import Oidc from './screens/Oidc';
import { Toaster } from './toaster';
import common_en from './translations/en/common.json';
import home_en from './translations/en/home.json';
import overview_en from './translations/en/overview.json';
import { config, oidcConfig } from './utils/config';
import Overview from './screens/Admin/Overview';

i18next.init({
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      common: common_en,
      home: home_en,
      overview: overview_en
    }
  }
});

function App() {
  return (
    <div className="app h-screen">
      <RecoilRoot>
        <Router>
          <AuthenticationProvider
            configuration={oidcConfig}
            loggerLevel={oidcLog.ERROR}
            isEnabled={true}
            UserStore={WebStorageStateStore}
            callbackComponentOverride={Oidc}
            notAuthenticated={() => {
              return <h1>Not Authenticated.</h1>;
            }}
            notAuthorized={() => {
              return <h1>Not Authorized.</h1>;
            }}
            authenticating={Oidc}
          >
            <Switch>
              <Route exact={true} path="/unauthorized" component={Unauthorized} />
              <Route exact={true} path="/missing-license" component={MissingLicense} />
              <Route exact={true} path="/not-found" component={NotFound} />

              <OidcSecure>
                <SetAuthToken>
                  <SetOidcRoutesContext>
                    <I18nextProvider i18n={i18next}>
                      <WhiteLabelProvider
                        configuration={{
                          centerpointApiUrl: config.centerpointBaseUrl
                        }}
                      >
                        <AdminLayoutRoute
                          exact={true}
                          path="/"
                          nav="home"
                          component={Dashboard}
                          featureEnabled={true}
                        />

                        <AdminLayoutRoute
                          exact={true}
                          path="/home"
                          nav="home"
                          component={Dashboard}
                          featureEnabled={true}
                        />

                        <AdminLayoutRoute
                          exact={true}
                          path="/overview"
                          nav="overview"
                          component={Overview}
                          featureEnabled={true}
                        />
                      </WhiteLabelProvider>
                    </I18nextProvider>
                  </SetOidcRoutesContext>
                </SetAuthToken>
              </OidcSecure>
            </Switch>
          </AuthenticationProvider>
        </Router>
        <Toaster />
      </RecoilRoot>
    </div>
  );
}

function AdminLayoutRoute({
  component: Component,
  featureEnabled,
  nav,
  subMenuNav,
  licenseId,
  ...rest
}) {
  const { hasLicense } = useLicense();

  return (
    <Route
      {...rest}
      render={props => (
        <ProtectedRoute hasLicense={hasLicense(licenseId)} featureEnabled={featureEnabled}>
          <AdminLayout nav={nav} subMenuNav={subMenuNav} {...props}>
            <Component {...props} />
          </AdminLayout>
        </ProtectedRoute>
      )}
    />
  );
}

const ProtectedRoute = ({ featureEnabled, hasLicense, children }) => {
  return featureEnabled && hasLicense ? <>{children}</> : <Redirect to={'/'} />;
};

const SetOidcRoutesContext = ({ children }) => {
  const loadAction = useToastAction();
  const [userProfile, setUserProfile] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const loadUserContextData = async () => {
    loadAction.execute(async () => {
      const userProfilePromise = getUserProfile();
      const customerPromise = getCustomerInfo();
      const res = await Promise.all([userProfilePromise, customerPromise]);

      i18next.changeLanguage(res[0].languageCode);
      setUserProfile(res[0]);
      setCustomerInfo(res[1]);
    }, 'Failed to load user data');
  };

  useEffect(() => {
    loadUserContextData();
  }, []);

  return (
    !loadAction.isExecuting && (
      <OidcRoutesContext.Provider
        value={{
          userProfile,
          customerInfo
        }}
      >
        {children}
      </OidcRoutesContext.Provider>
    )
  );
};

/**
 * Small route wrapper that gets the oidc user and sets the axios auth header
 */
const SetAuthToken = ({ children }) => {
  const { oidcUser, events } = useReactOidc();

  setAuthHeader(oidcUser.access_token);

  useEffect(() => {
    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events]);

  const addUserSignedOut = () => {
    const userManager = getUserManager();
    userManager.removeUser();
    window.location.reload(false);
  };

  return <>{children}</>;
};

export default App;
