export const SubmitButton = ({
  text,
  disabled,
  className,
}) => {
  return (
    <button
      type="submit"
      disabled={disabled === true}
      className={`inline-flex items-center px-4 py-2 border border-transparent font-medium rounded text-white bg-brand-pink hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 focus:border-gray-400 null text-sm ${className}`}
    >
      {text}
    </button>
  )
}